<template>
  <div class="location-management-table">
    <screen-container class="location-management-table__container"
      ><v-card>
        <v-card-title>
          <div class="tw-flex tw-w-full tw-items-center">
            <v-text-field
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="filtersSearch"
            ></v-text-field>
            <v-spacer />
            <v-popover popoverInnerClass="popover-inner no-overflow" placement="bottom-end" :autoHide="true">
              <ui-button button="primary" icon="filter" :label="$t('common.button.filters')" class="tw-text-sm" />
              <template slot="popover">
                <div class="location-management-table__container__popover">
                  <locations-list-filters
                    :client-list-groups="existingGroups"
                    :product-locator-status="currentClient.productLocatorStatus"
                    @onUpdateFilters="onUpdateFilters($event, 'filters')"
                  />
                </div>
              </template>
            </v-popover>
          </div>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="locations"
          show-select
          item-key="id"
          :loading="isLoading"
        ></v-data-table> </v-card
    ></screen-container>
    <UiActions large centered>
      <v-btn type="submit" rounded color="primary" @click="onPrevious()">
        {{ $t('locations.list.manage.stepper.btn.prev') }}
      </v-btn>
      <v-btn type="submit" rounded color="primary" @click="onSubmitModify(selected)">
        {{ $t('locations.list.manage.stepper.btn.next') }}
      </v-btn>
    </UiActions>

    <location-management-updater
      ref="history"
      :dialog="isEditing"
      :columns="columns"
      :groups="existingGroups"
      :is-saving="isSaving"
      @close="closeModal"
      @modifyColumns="modifyColumns"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ScreenContainer from '@/components/Screen/Container.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiButton from '@/components/UI/Button.vue'
import LocationManagementUpdater from '@/components/Location/Management/Updater.vue'
import { debounce } from '@/utils/func.util'
import LocationsListFilters from '@/views/Locations/ListFilters'

export default {
  name: 'LocationManageTable',
  components: {
    ScreenContainer,
    UiActions,
    UiButton,
    LocationManagementUpdater,
    LocationsListFilters,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    existingGroups: {
      type: Array,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      updatedColumns: [],
      headers: [],
      selected: [],
      reducedItems: [],
      isEditing: false,
      filterOpened: false,
      stringifiedSearchFilters: '',
      searchFilters: {},
      isLoading: false,
    }
  },
  watch: {
    stringifiedSearchFilters() {
      this.loadLocations({ filters: this.stringifiedSearchFilters }, true, true)
    },
  },
  mounted() {
    let specificColumns = [
      {
        key: 'id',
        label: 'Id',
      },
      { key: 'name', label: 'Name' },
    ]
    this.updatedColumns = specificColumns.concat(this.columns)
    let newUpdatedColums = this.updatedColumns.map(({ key: value, label: text }) => ({
      value,
      text,
    }))
    this.headers = newUpdatedColums
  },

  computed: {
    ...mapState({
      locations: state => state.location.locations,
    }),
    modalTitle() {
      return 'test'
    },
  },

  methods: {
    ...mapActions({
      getLocations: 'location/getLocations',
    }),
    onSubmitModify(items) {
      this.reducedItems = items.map(item => ({
        id: item.id,
        name: item.name,
        group: item.group,
        customLink: item.customLink,
      }))
      this.isEditing = true
    },
    closeModal() {
      this.isEditing = false
    },
    modifyColumns(modifiedColumns) {
      let formattedValues = []
      this.selected.forEach(location => {
        let modifiedObject = {}
        modifiedColumns.forEach(column => {
          modifiedObject[column.key] = column.newValue
        })
        modifiedObject['id'] = location.id
        formattedValues.push(modifiedObject)
      })
      this.$emit('submitModifications', formattedValues)
    },
    filtersSearch: debounce(function (value) {
      this.search = value
      this.loadLocations({ filters: this.stringifiedSearchFilters }, true)
    }, 900),
    onPrevious() {
      this.$emit('previousStep', 2)
    },
    onUpdateFilters(stringifiedFilters) {
      this.searchFilters.filters = stringifiedFilters
      this.stringifiedSearchFilters = this.getStringifiedSearchFilters()
    },
    getStringifiedSearchFilters() {
      return Object.values(this.searchFilters)
        .map(filter => filter)
        .join(',')
    },

    loadLocations(tableParams = null, resetLocations = false) {
      this.isLoading = true
      this.getLocations({ tableParams, resetLocations, search: this.search })
        .then(() => {
          this.totalRows = this.locationsPagination.nbResults
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.location-management-table {
  @apply tw-flex tw-flex-col tw-flex-auto tw-gap-8 tw-justify-center tw-items-center tw-h-full #{!important};

  &__container {
    @apply tw-flex tw-flex-col tw-flex-auto tw-gap-8 tw-justify-center tw-items-center tw-h-full tw-p-4 sm:tw-p-6 #{!important};

    padding-bottom: 89px !important;

    @media (min-width: $screen-sm) {
      padding-bottom: 97px !important;
    }

    &__popover {
      display: flex;
      flex: 1;
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }
      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
