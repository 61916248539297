<template>
  <screen-container class="locations-management-summary" full-height direction="column">
    <v-icon :size="128" :color="status">
      <template v-if="status === 'success'">{{ icons.mdiCheckboxMarkedCircleOutline }}</template>
      <template v-else-if="status === 'warning'">{{ icons.mdiAlertCircleOutline }}</template>
      <template v-else-if="status === 'error'">{{ icons.mdiCloseCircleOutline }}</template>
    </v-icon>
    <div class="locations-management-summary__title">
      {{ $t(`locations.list.manage.summary.${status}.title`) }}
    </div>
    <div class="locations-management-summary__subtitle">
      {{ $t(`locations.list.manage.summary.${status}.subtitle`) }}
    </div>
    <div class="locations-management-summary__errors" v-if="errors && errors.length">
      <v-data-table
        disable-sort
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="details"
        class="elevation-1"
      >
      </v-data-table>
    </div>
    <v-btn rounded color="primary" class="locations-management-summary__cta" :to="{ name: 'Locations' }">
      {{ $t('locations.id.error.button.back') }}
    </v-btn>
  </screen-container>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline, mdiAlertCircleOutline, mdiCloseCircleOutline } from '@mdi/js'
import ScreenContainer from '@/components/Screen/Container.vue'

export default {
  name: 'LocationManagementSummary',
  components: {
    ScreenContainer,
  },
  props: {
    results: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiCheckboxMarkedCircleOutline,
      mdiAlertCircleOutline,
      mdiCloseCircleOutline,
    },
  }),
  computed: {
    status() {
      if (this.results.data?.length && !this.results.errors) {
        return 'success'
      } else if (this.results.data?.length && this.results.errors.length) {
        return 'warning'
      } else if (!this.results.data && this.results.errors?.length) {
        return 'error'
      }
      return ''
    },
    headers() {
      const defaultColumn = [
        {
          text: this.$t(`locations.list.manage.fields.line`),
          value: 'line',
        },
      ]
      const rowColumns = Object.keys(this.errors[0].row).map(key => ({
        text: this.$t(`locations.list.manage.fields.${key}`),
        value: key,
      }))
      return defaultColumn.concat(rowColumns)
    },
    details() {
      return (
        this.errors &&
        this.errors.map(error => ({
          line: error.line,
          ...error.row,
        }))
      )
    },
    errors() {
      return this.results.errors
    },
  },
}
</script>

<style lang="scss" scoped>
.locations-management-summary {
  @apply tw-mt-0 tw-justify-center tw-items-center #{!important};

  &__title {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__subtitle {
    margin-top: 0.5rem;
  }

  &__errors,
  &__cta {
    margin-top: 1.5rem;
  }
}
</style>
