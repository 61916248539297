var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"location-management-type"},[_c('screen-container',{staticClass:"location-management-type__container"},[_c('v-item-group',{staticClass:"location-management-type__container__group",attrs:{"value":_vm.importType,"mandatory":""},on:{"change":_vm.onChange}},[_c('v-item',{attrs:{"value":"csv"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"location-management-type__container__group__card",class:{
              'tw-ring-2 tw-ring-primary': active,
              'tw-bg-black tw-bg-opacity-5': hover,
            },attrs:{"width":"300px"},on:{"click":toggle}},[(active)?_c('v-icon',{staticClass:"tw-absolute tw-top-2 tw-right-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e(),_c('v-icon',{attrs:{"size":72,"color":active ? 'primary' : null}},[_vm._v(_vm._s(_vm.icons.mdiMicrosoftExcel))]),_c('ui-title',{staticClass:"tw-mt-2 tw-self-center",attrs:{"title":_vm.$t('locations.list.manage.type.csv.title')}}),_c('ui-title',{staticClass:"tw-text-center tw-text-sm tw-font-normal",attrs:{"title":_vm.$t('locations.list.manage.type.csv.subtitle')}})],1)]}}],null,true)})]}}])}),_c('v-item',{attrs:{"value":"manual"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{staticClass:"location-management-type__container__group__card",class:{
              'tw-ring-2 tw-ring-primary': active,
              'tw-bg-black tw-bg-opacity-5': hover,
            },attrs:{"width":"300px"},on:{"click":toggle}},[(active)?_c('v-icon',{staticClass:"tw-absolute tw-top-2 tw-right-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e(),_c('v-icon',{attrs:{"size":72,"color":active ? 'primary' : null}},[_vm._v(_vm._s(_vm.icons.mdiOrderBoolAscendingVariant))]),_c('ui-title',{staticClass:"tw-mt-2 tw-self-center",attrs:{"title":_vm.$t('locations.list.manage.type.manual.title')}}),_c('ui-title',{staticClass:"tw-text-center tw-text-sm tw-font-normal",attrs:{"title":_vm.$t('locations.list.manage.type.manual.subtitle')}})],1)]}}],null,true)})]}}])})],1),_c('UiActions',{attrs:{"large":"","centered":""}},[_c('v-btn',{attrs:{"type":"submit","rounded":"","color":"primary"},on:{"click":function($event){return _vm.onPrevious()}}},[_vm._v(" "+_vm._s(_vm.$t('locations.list.manage.stepper.btn.prev'))+" ")]),_c('v-btn',{attrs:{"type":"submit","rounded":"","color":"primary"},on:{"click":function($event){return _vm.onSubmitType(_vm.importType)}}},[_vm._v(" "+_vm._s(_vm.$t('locations.list.manage.stepper.btn.next'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }