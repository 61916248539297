<template>
  <div class="locations-management">
    <v-stepper class="tw-h-full tw-flex tw-flex-col" flat v-model="currentStep" tile>
      <v-stepper-header class="locations-management__header" :class="{ 'theme--dark': $vuetify.theme.dark }">
        <template v-for="(step, idx) in steps">
          <v-stepper-step :complete="currentStep > idx + 1" :step="idx + 1" :key="`steps${idx}`">
            {{ $t(`locations.list.manage.stepper.header.${manageType}.${idx + 1}`) }}
          </v-stepper-step>

          <v-divider v-if="idx + 1 < steps" :key="`divider${idx}`"></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items
        class="locations-management__items tw-h-full tw-overflow-y-auto"
        :class="{ 'theme--dark': $vuetify.theme.dark }"
      >
        <location-management-picker
          v-if="currentStep === 1"
          :manageFields="manageFields"
          @loadTypePicker="loadTypePicker"
        />
        <location-management-type v-if="currentStep === 2" @typeSelect="typeSelect" @previousStep="previousStep" />
        <location-management-importer
          v-if="currentStep === 3 && type === 'csv'"
          :columns="columns"
          @previousStep="previousStep"
          @submitModifications="submitModifications"
        />
        <location-management-table
          v-if="currentStep === 3 && type === 'manual'"
          :columns="columns"
          :existingGroups="existingGroups"
          :current-client="currentClient"
          :is-saving="isSaving"
          @previousStep="previousStep"
          @submitModifications="submitModifications"
        />
        <location-management-summary v-if="currentStep === 4 && results" :results="results" />
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import LocationManagementPicker from '@/components/Location/Management/Picker.vue'
import LocationManagementImporter from '@/components/Location/Management/Importer.vue'
import LocationManagementType from '@/components/Location/Management/Type.vue'
import LocationManagementSummary from '@/components/Location/Management/Summary.vue'
import LocationManagementTable from '@/components/Location/Management/Table.vue'
import { manageFields } from '@/config/manager.config'
import { mapActions, mapState } from 'vuex'
import { getClientGroups } from '@/services/location.service'
import { notif } from '@/mixins/notification.mixin'

export default {
  name: 'LocationsManager',
  components: {
    LocationManagementPicker,
    LocationManagementType,
    LocationManagementImporter,
    LocationManagementSummary,
    LocationManagementTable,
  },
  mixins: [notif],
  data() {
    return {
      currentStep: 1,
      steps: 4,
      manageType: 'csv',
      manageFields,
      columns: [],
      bodyJson: null,
      type: '',
      existingGroups: null,
      search: '',
      results: null,
      isSaving: false,
    }
  },
  async mounted() {
    const { data } = await getClientGroups(this.currentClient.id)
    this.existingGroups = data.results.map(group => ({
      ...group,
      name: group.value ? group.value : this.$t('locations.list.filters.group.noGroup'),
    }))
  },
  computed: {
    ...mapState({
      locations: state => state.location.locations,
      currentClient: state => state.client.currentClient,
    }),
  },
  methods: {
    ...mapActions({
      updateLocationInfo: 'location/updateLocationInfo',
      getLocations: 'location/getLocations',
    }),
    loadTypePicker(columns) {
      this.columns = columns
      this.currentStep = 2
    },
    async typeSelect(type) {
      if (type === 'csv') {
        this.loadNuvoHeaders(this.columns)
      } else {
        this.currentStep = 3
        this.type = 'manual'
      }
    },
    previousStep(step) {
      this.currentStep = step
    },
    loadNuvoHeaders(columns) {
      this.columns = columns
      this.currentStep = 3
      this.type = 'csv'
    },
    async submitModifications(values) {
      this.bodyJson = { updates: values }
      this.isSaving = true

      try {
        const response = await this.updateLocationInfo(this.bodyJson)
        this.results = response
        this.currentStep = 4
      } catch {
        this.notificationError()
      }
      this.isSaving = false
    },
  },
}
</script>
