<template>
  <div class="location-management-picker">
    <screen-container direction="column" class="tw-mt-6">
      <div>
        <ul class="tw-list-none">
          <li v-for="(field, i) in manageFields" :key="i" class="location-management-picker__category">
            <div class="location-management-picker__category__title">
              {{ $t(`locations.list.manage.fields.${field.key}`) }}
            </div>
            <div class="location-management-picker__category__content">
              <div
                class="location-management-picker__category__content__item"
                v-for="(child, j) in field.children"
                :key="j"
              >
                <v-checkbox
                  v-model="checkbox[j]"
                  :label="$t(`locations.list.manage.fields.${child.key}`)"
                  @click="checkField(child, checkbox[j], j)"
                ></v-checkbox>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <UiActions large centered>
        <v-btn type="submit" rounded color="primary" @click="onSubmitPicker()">
          {{ $t('locations.list.manage.stepper.btn.next') }}
        </v-btn>
      </UiActions>
    </screen-container>
  </div>
</template>

<script>
import ScreenContainer from '@/components/Screen/Container.vue'
import UiActions from '@/components/UI/Actions.vue'

export default {
  name: 'LocationManagementPicker',
  components: {
    ScreenContainer,
    UiActions,
  },
  props: {
    manageFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: [],
      checkedFields: [],
      checkbox: [],
    }
  },
  methods: {
    checkField(item, checked, index) {
      this.checkedFields[index] = { ...item, modified: checked }
    },
    onSubmitPicker() {
      this.$emit('loadTypePicker', this.checkedFields)
    },
  },
}
</script>

<style lang="scss">
.location-management-picker {
  &__category {
    &__title {
      @apply tw-p-4 tw-text-white tw-font-bold tw-rounded-tr-md tw-rounded-tl-md;

      background: $generic-color-gtr;
    }

    &__content {
      @apply tw-flex;

      &__item {
        @apply tw-p-4 tw-rounded-md tw-bg-white;
      }
    }
  }
}
</style>
