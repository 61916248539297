<template>
  <UiDialog :dialog="dialog" :max-width="500" scrollable>
    <template v-slot:header>
      <div class="tw-flex tw-items-center">
        <UiTitle class="tw-text-base tw-font-bold" :title="$t('locations.list.manage.updater.modal.title')" />
        <v-spacer />
        <v-btn class="tw-h-0" @click="closeModal" icon
          ><v-icon>{{ icons.mdiClose }}</v-icon></v-btn
        >
      </div></template
    >
    <template v-slot:body>
      <div v-for="(column, index) in columns" :key="index" class="tw-p-4">
        <v-select
          v-if="column.key === 'group'"
          v-model="newValues[index]"
          :items="groups"
          item-text="name"
          item-value="value"
          :label="column.label"
          outlined
          hide-details
        ></v-select>
        <v-text-field v-else :label="column.label" outlined v-model="newValues[index]" hide-details></v-text-field>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn type="submit" rounded text :loading="isSaving" @click="sendModifications">
        {{ $t('locations.list.manage.updater.modal.btn') }}
      </v-btn>
    </template>
  </UiDialog>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiDialog from '@/components/UI/Dialog.vue'
import { mdiClose } from '@mdi/js'

let initialData = () => ({
  confirm: false,
})

export default {
  name: 'LocationManagementUpdater',
  components: {
    UiTitle,
    UiDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: false,
      default: () => [],
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newValues: [],
      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    resetData() {
      Object.assign(this.$data, initialData())
    },

    closeModal() {
      this.$emit('close')
    },

    sendModifications() {
      let modifiedColumns = []
      this.columns.forEach((column, index) => {
        modifiedColumns.push({
          ...column,
          newValue: this.newValues[index],
        })
      })
      this.$emit('modifyColumns', modifiedColumns)
    },
  },
}
</script>
