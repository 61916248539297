import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=7c2f9d2c&scoped=true&"
import script from "./Dialog.vue?vue&type=script&lang=js&"
export * from "./Dialog.vue?vue&type=script&lang=js&"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=7c2f9d2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c2f9d2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VDialog,VDivider})
