<template>
  <div class="locations-management-importer tw-h-full"><div id="nuvo-container" class="tw-h-full" /></div>
</template>

<script>
import NuvoImporter from 'nuvo-vanilla-js/index.umd'
import { NUVO_API_KEY } from '@/config/manager.config'

export default {
  name: 'LocationManagementImporter',
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    updatedColumns: [],
  }),
  computed: {
    settings() {
      return {
        developerMode: true,
        modal: false,
        identifier: 'product_data',
        columns: this.columns,
      }
    },
  },
  mounted() {
    this.updatedColumns = this.columns.push({
      key: 'id',
      label: 'Id',
    })

    const nuvoImporter = new NuvoImporter(NUVO_API_KEY, this.settings)
    nuvoImporter.setText('Select File')
    nuvoImporter.launch()

    const target = document.getElementById(nuvoImporter.getId())
    document.getElementById('nuvo-container').append(target)

    // eslint-disable-next-line
    nuvoImporter.onResults((values, identifier) => {
      this.$emit('submitModifications', values)
    })
  },
}
</script>

<style lang="scss">
.locations-management-importer {
  @apply tw-h-full;

  iframe {
    @apply tw-h-full #{!important};
  }
}
</style>
