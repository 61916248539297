export const NUVO_API_KEY =
  process.env.CONTEXT === 'production'
    ? 'UVpzLy4Ty7CyCXWUd/5qUzOOGmhUHkJlDpQq6r0dnyI='
    : '5ovR2uvMX/EAp3/+VesztETkggbQzff0W3p6OlbVfLU='

export const manageFields = [
  {
    key: 'location',
    label: 'Location',
    children: [
      {
        key: 'group',
        label: 'Internal grouping',
      },
      // {
      //   key: 'customLink',
      //   label: 'Custom link',
      // },
    ],
  },
]
