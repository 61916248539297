<template>
  <div class="location-management-type">
    <screen-container class="location-management-type__container">
      <v-item-group :value="importType" class="location-management-type__container__group" mandatory @change="onChange">
        <v-item v-slot="{ active, toggle }" value="csv">
          <v-hover v-slot="{ hover }">
            <v-card
              class="location-management-type__container__group__card"
              :class="{
                'tw-ring-2 tw-ring-primary': active,
                'tw-bg-black tw-bg-opacity-5': hover,
              }"
              width="300px"
              @click="toggle"
            >
              <v-icon v-if="active" color="primary" class="tw-absolute tw-top-2 tw-right-2">
                {{ icons.mdiCheckCircle }}
              </v-icon>
              <v-icon :size="72" :color="active ? 'primary' : null">{{ icons.mdiMicrosoftExcel }}</v-icon>
              <ui-title class="tw-mt-2 tw-self-center" :title="$t('locations.list.manage.type.csv.title')" />
              <ui-title
                class="tw-text-center tw-text-sm tw-font-normal"
                :title="$t('locations.list.manage.type.csv.subtitle')"
              />
            </v-card>
          </v-hover>
        </v-item>
        <v-item v-slot="{ active, toggle }" value="manual">
          <v-hover v-slot="{ hover }">
            <v-card
              class="location-management-type__container__group__card"
              :class="{
                'tw-ring-2 tw-ring-primary': active,
                'tw-bg-black tw-bg-opacity-5': hover,
              }"
              width="300px"
              @click="toggle"
            >
              <v-icon v-if="active" color="primary" class="tw-absolute tw-top-2 tw-right-2">
                {{ icons.mdiCheckCircle }}
              </v-icon>
              <v-icon :size="72" :color="active ? 'primary' : null">{{ icons.mdiOrderBoolAscendingVariant }}</v-icon>
              <ui-title class="tw-mt-2 tw-self-center" :title="$t('locations.list.manage.type.manual.title')" />
              <ui-title
                class="tw-text-center tw-text-sm tw-font-normal"
                :title="$t('locations.list.manage.type.manual.subtitle')"
              />
            </v-card>
          </v-hover>
        </v-item>
      </v-item-group>

      <UiActions large centered>
        <v-btn type="submit" rounded color="primary" @click="onPrevious()">
          {{ $t('locations.list.manage.stepper.btn.prev') }}
        </v-btn>
        <v-btn type="submit" rounded color="primary" @click="onSubmitType(importType)">
          {{ $t('locations.list.manage.stepper.btn.next') }}
        </v-btn>
      </UiActions>
    </screen-container>
  </div>
</template>

<script>
import { mdiMicrosoftExcel, mdiOrderBoolAscendingVariant, mdiCheckCircle } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import UiActions from '@/components/UI/Actions.vue'

export default {
  name: 'LocationManageType',
  components: {
    UiTitle,
    ScreenContainer,
    UiActions,
  },
  data() {
    return {
      toggle: false,
      icons: {
        mdiMicrosoftExcel,
        mdiOrderBoolAscendingVariant,
        mdiCheckCircle,
      },
      selectedfrontOffice: '',
      importType: '',
    }
  },
  methods: {
    onChange(value) {
      this.importType = value
    },

    onSubmitType() {
      this.$emit('typeSelect', this.importType)
    },

    onPrevious() {
      this.$emit('previousStep', 1)
    },
  },
}
</script>

<style lang="scss">
.location-management-type {
  @apply tw-flex tw-flex-col tw-flex-auto tw-gap-8 tw-justify-center tw-items-center tw-h-full #{!important};

  &__container {
    @apply tw-flex tw-flex-col tw-flex-auto tw-gap-8 tw-justify-center tw-items-center tw-h-full tw-p-4 sm:tw-p-6 #{!important};

    padding-bottom: 89px !important;

    @media (min-width: $screen-sm) {
      padding-bottom: 97px !important;
    }

    &__group {
      @apply tw-flex tw-flex-row tw-justify-center tw-gap-8 #{!important};

      &__card {
        @apply tw-p-6 tw-text-center tw-flex tw-items-center tw-flex-col tw-gap-4;

        &:before {
          background: transparent;
        }
      }
    }
  }
}
</style>
